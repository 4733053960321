import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { motion, AnimatePresence } from "framer-motion"

const duration = 0.8

const variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 0,
    transition: { duration: duration },
  },
}
const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <link rel="canonical" href="index.html" />
        <title>John Ojetunde | Software Engineer</title>
        <link rel="apple-touch-icon" sizes="57x57" href="favicons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="favicons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="favicons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="favicons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="favicons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="favicons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="favicons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="favicons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="favicons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="favicons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="favicons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="favicons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="favicons/favicon-16x16.png" />
        <link rel="manifest" href="favicons/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="favicons/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <main>
        <AnimatePresence>
          <motion.main
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
          </motion.main>
        </AnimatePresence>
      </main>
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
